/** Montserrat Regular **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix')
format('embedded-opentype'),
url('../fonts/Montserrat-Regular.woff2') format('woff2'),
url('../fonts/Montserrat-Regular.woff') format('woff');
}

/** Montserrat Medium **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat-Medium.eot?#iefix')
format('embedded-opentype'),
url('../fonts/Montserrat-Medium.woff2') format('woff2'),
url('../fonts/Montserrat-Medium.woff') format('woff');
}

/** Montserrat Bold **/
@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
url('../fonts/Montserrat-Bold.woff2') format('woff2'),
url('../fonts/Montserrat-Bold.woff') format('woff');
}

/* PTSans */

@font-face {
    font-family: 'PTSans';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/PTSans-Regular.woff');
    src: url('../fonts/PTSans-Regular.ttf');
    src: url('../fonts/PTSans-Regular.eot');
}

@font-face {
    font-family: 'PTSans';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/PTSans-Bold.woff');
    src: url('../fonts/PTSans-Bold.ttf');
    src: url('../fonts/PTSans-Bold.eot');
}
